import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

export default {
  path: '/',
  component: Entities,
  children: [
  ],
};
